@tailwind base;
h1 {
  @apply text-3xl;
}
h2 {
  @apply text-3xl;
}
h3 {
  @apply text-lg;
}
a {
  @apply text-blue-600 underline;
}

@tailwind components;
@tailwind utilities;

.wrap {
  background-image: url(../src/asserts/images/bg.jpg);
}

@font-face {
  font-family: "GothamPro-Bold";
  src: url("./asserts/fonts/GothamPro-GothamPro-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "GothamPro-Regular";
  src: url("./asserts/fonts/GothamPro-GothamPro-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "cocogoose";
  src: url("./asserts/fonts/Cocogoose-Cocogoose.ttf");
  font-weight: normal;
}

body {
  font-family: "GothamPro-Regular";
}

.interact-button {
  font-family: ikimono;
  text-transform: uppercase;
  background-color: #ffe100;
  color: #000;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
}
